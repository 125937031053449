export class FirestoreCollection {
  // root collection
  static Stats = 'stats';

  // root collection
  static Candidates = 'candidates';

  // root collection
  static CandidateSettings = 'candidate_settings';

  // root collection
  static CandidateProfiles = 'candidate_profiles';

  // root collection
  static CandidateStats = 'candidate_stats';

  // root collection
  static Campaigns = 'campaigns';

  // root collection
  static Events = 'events';

  // root collection
  static News = 'news';

  // root collection
  static Activities = 'activities';

  // root collection
  static Accounts = 'accounts';

  // root collection
  static Users = 'users';

  // nested in users
  static UserDailyRecruitHistory = 'daily_recruit_history';

  // root collection
  static UserStats = 'user_stats';

  // root collection
  static UserLikes = 'user_likes';

  // root collection
  static DownlineSummary = 'user_downline_summary';

  // root collection
  static Surveys = 'surveys';

  // nested in surveys
  static SurveyResponses = 'responses';

  // root collection
  static SurveyStats = 'survey_stats';

  // root collection
  static Logs = 'logs';

  // root collection
  static Suggestions = 'suggestions';

  // root collection
  static Drafts = 'drafts';

  // root collection
  static Broadcasts = 'broadcasts';

  // root collection
  static Aspirations = 'aspirations';

  // root collection
  static VotingBooths = 'tps_2024';

  // root collection
  static ElectionObservers = 'election_observers';

  // root collection
  static RealCount = 'real_count';

  // root collection
  static RealCountStats = 'real_count_stats';
}
