export class CandidateRegionLevels {
  // Level wilayah untuk gubernur
  static Provinsi = 'provinsi';

  // Level wilayah untuk caleg DPR RI, caleg DPRD Provinsi,
  // caleg DPRD Kabupaten Kota, bupati dan walikota
  static KabupatenKota = 'kabupaten_kota';

  static Kecamatan = 'kecamatan';
}
