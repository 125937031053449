import { ref } from 'vue';

export function useLoading() {
  const state = ref(false);

  function startLoading() {
    state.value = true;
  }

  function finishLoading() {
    state.value = false;
  }

  return {
    state,
    startLoading,
    finishLoading,
  };
}
