import { ref, watchEffect, computed } from 'vue';
import { defineStore } from 'pinia';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';

import { CandidateSetupPhases, FirestoreCollection, Roles } from '@pantaurelawan/shared/config';
import { PantauRelawanCandidate, SidebarMenu } from '@pantaurelawan/shared/data-access/models';

import { useAuthUserStore } from './auth-user';
import { useRouter, useRoute } from 'vue-router';

export const useAppSidebarMenuStore = defineStore('appSidebarMenu', () => {
  const db = getFirestore();
  const authUserStore = useAuthUserStore();
  const authUser = computed(() => authUserStore.state);

  const candidate = ref<PantauRelawanCandidate>();
  const state = ref<SidebarMenu[]>([]);
  const router = useRouter();
  const route = useRoute();

  watchEffect(onCleanup => {
    const user = authUser.value;

    if (!user) return;

    const unsubscribe = onSnapshot(
      doc(db, FirestoreCollection.Candidates, user.id_kandidat),
      snap => {
        candidate.value = snap.data() as PantauRelawanCandidate;
      }
    );

    onCleanup(unsubscribe);
  });

  watchEffect(() => {
    if (!authUser.value || !candidate.value) return;

    const currentRoute = router.currentRoute.value;
    if (
      currentRoute.path === `/real-count/saksi/${route.params.id}/tunjuk-relawan` ||
      currentRoute.path === `/real-count/saksi/${route.params.id}/tunjuk-dukungan`
    ) {
      state.value = buildForTunjukSaksi(route.params.id.toString());
      return;
    }

    if (candidate.value.setup_phase !== CandidateSetupPhases.Selesai) {
      state.value = buildForSetup();

      return;
    }

    if (authUser.value.roles.includes(Roles.Kandidat)) {
      state.value = buildForCandidate(candidate.value.mode);

      return;
    }

    if (authUser.value.roles.includes(Roles.Admin)) {
      state.value = buildForAdmin(candidate.value.mode);

      return;
    }

    if (authUser.value.roles.includes(Roles.Relawan)) {
      state.value = buildForUser();

      return;
    }
  });

  return { state };
});

function buildForTunjukSaksi(id: string): SidebarMenu[] {
  return [
    {
      text: 'Pilih Saksi',
      is_header: true,
    },
    {
      url: `/real-count/saksi/${id}/tunjuk-relawan`,
      icon: 'bi bi-cpu',
      text: 'Relawan',
    },
    {
      url: `/real-count/saksi/${id}/tunjuk-dukungan`,
      icon: 'bi bi-cpu',
      text: 'Dukungan',
    },
  ];
}

// Menu sidebar untuk candidate yang masih setup
function buildForSetup(): SidebarMenu[] {
  return [
    {
      text: 'Navigation',
      is_header: true,
    },
    {
      url: '/setup',
      icon: 'bi bi-cpu',
      text: 'Setup',
    },
  ];
}

// Menu sidebar untuk koordinator pusat
function buildForAdmin(mode: 'basic' | 'exclusive'): SidebarMenu[] {
  return [
    {
      text: 'Navigation',
      is_header: true,
    },
    {
      url: '/home',
      icon: 'bi bi-cpu',
      text: 'Dashboard',
    },
    {
      url: '/relawan',
      icon: 'bi bi-cpu',
      text: 'List Relawan',
    },
    {
      url: '/dukungan',
      icon: 'bi bi-cpu',
      text: 'List Dukungan',
    },
    {
      url: '/aktivitas',
      icon: 'bi bi-cpu',
      text: 'List Aktivitas',
    },
    {
      url: '/pengumuman',
      icon: 'bi bi-cpu',
      text: 'List Pengumuman',
    },
    {
      url: '/survey',
      icon: 'bi bi-cpu',
      text: 'List Survey',
    },
    {
      url: '/aspirasi-relawan',
      icon: 'bi bi-cpu',
      text: 'List Aspirasi Relawan',
    },
    {
      url: '/aspirasi-masyarakat',
      icon: 'bi bi-cpu',
      text: 'List Aspirasi Masyarakat',
    },
    {
      text: 'Real Count',
      is_header: true,
    },
    {
      url: '/real-count/kandidat',
      icon: 'bi bi-cpu',
      text: 'List Kandidat',
    },
    {
      url: '/real-count/saksi',
      icon: 'bi bi-cpu',
      text: 'List Saksi',
    },
    {
      url: '/real-count/data-masuk',
      icon: 'bi bi-cpu',
      text: 'List Data Masuk',
    },
    {
      url: '/real-count/rekapitulasi-suara',
      icon: 'bi bi-cpu',
      text: 'Rekapitulasi Suara',
    },
    
    ...(mode === 'exclusive'
      ? [
          {
            text: 'Menu Profiling Kandidat',
            is_header: true,
          },
          {
            url: '/profil-kandidat',
            icon: 'bi bi-cpu',
            text: 'Profil Kandidat',
          },
          {
            url: '/program',
            icon: 'bi bi-cpu',
            text: 'Program',
          },
          {
            url: '/berita',
            icon: 'bi bi-cpu',
            text: 'Berita',
          },
          {
            url: '/events',
            icon: 'bi bi-cpu',
            text: 'Kegiatan',
          },
        ]
      : []),
    {
      text: 'Pengaturan',
      is_header: true,
    },
    {
      url: '/struktur-timses',
      icon: 'bi bi-cpu',
      text: 'Struktur Timses',
    },
    {
      url: '/dpt-lock',
      icon: 'bi bi-cpu',
      text: 'Kunci DPT',
    },
    {
      url: '/input-relawan',
      icon: 'bi bi-cpu',
      text: 'Input Form Relawan',
    },
    {
      url: '/hak-akses',
      icon: 'bi bi-cpu',
      text: 'Hak Akses Relawan',
    },
    {
      url: '/kategori-aktivitas',
      icon: 'bi bi-cpu',
      text: 'Kategori Aktivitas',
    },
    {
      url: '/status-dukungan',
      icon: 'bi bi-cpu',
      text: 'Status Dukungan',
    },
  ];
}

// Menu sidebar untuk kandidat
function buildForCandidate(mode: 'basic' | 'exclusive'): SidebarMenu[] {
  return [
    {
      text: 'Navigation',
      is_header: true,
    },
    {
      url: '/home',
      icon: 'bi bi-cpu',
      text: 'Dashboard',
    },
    {
      url: '/relawan',
      icon: 'bi bi-cpu',
      text: 'List Relawan',
    },
    {
      url: '/dukungan',
      icon: 'bi bi-cpu',
      text: 'List Dukungan',
    },
    {
      url: '/aktivitas',
      icon: 'bi bi-cpu',
      text: 'List Aktivitas',
    },
    {
      url: '/pengumuman',
      icon: 'bi bi-cpu',
      text: 'List Pengumuman',
    },
    {
      url: '/survey',
      icon: 'bi bi-cpu',
      text: 'List Survey',
    },
    {
      url: '/aspirasi-relawan',
      icon: 'bi bi-cpu',
      text: 'List Aspirasi Relawan',
    },
    {
      url: '/aspirasi-masyarakat',
      icon: 'bi bi-cpu',
      text: 'List Aspirasi Masyarakat',
    },
    ...(mode === 'exclusive'
      ? [
          {
            text: 'Menu Profiling Kandidat',
            is_header: true,
          },
          {
            url: '/profil-kandidat',
            icon: 'bi bi-cpu',
            text: 'Profil Kandidat',
          },
          {
            url: '/program',
            icon: 'bi bi-cpu',
            text: 'Program',
          },
          {
            url: '/berita',
            icon: 'bi bi-cpu',
            text: 'Berita',
          },
          {
            url: '/events',
            icon: 'bi bi-cpu',
            text: 'Kegiatan',
          },
        ]
      : []),
  ];
}

// Menug sidebar untuk relawan
function buildForUser(): SidebarMenu[] {
  return [
    {
      text: 'Navigation',
      is_header: true,
    },
    {
      url: '/home',
      icon: 'bi bi-cpu',
      text: 'Dashboard',
    },
    {
      url: '/relawan',
      icon: 'bi bi-cpu',
      text: 'List Relawan',
    },
    {
      url: '/dukungan',
      icon: 'bi bi-cpu',
      text: 'List Dukungan',
    },
    {
      url: '/aktivitas',
      icon: 'bi bi-cpu',
      text: 'List Aktivitas',
    },
  ];
}
