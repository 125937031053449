import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { DashboardLogin } from '@pantaurelawan/dashboard/auth/login/feature';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: DashboardLogin,
  },
  {
    path: '/setup',
    name: 'Setup',
    component: () => import('@pantaurelawan/dashboard/setup/feature'),
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@pantaurelawan/dashboard/home/feature'),
  },
  {
    path: '/relawan',
    name: 'ListRelawan',
    component: () => import('@pantaurelawan/dashboard/relawan/feature/list'),
  },
  {
    path: '/dukungan',
    name: 'ListDukungan',
    component: () => import('@pantaurelawan/dashboard/dukungan/feature/list'),
  },
  {
    path: '/dukungan/:id',
    name: 'EditDukungan',
    component: () => import('@pantaurelawan/dashboard/dukungan/feature/edit'),
  },
  {
    path: '/relawan/:id',
    name: 'DetailRelawan',
    component: () => import('@pantaurelawan/dashboard/relawan/feature/detail'),
  },
  {
    path: '/relawan/:id/rekrut-relawan',
    name: 'DetailRekrutanRelawan',
    component: () => import('@pantaurelawan/dashboard/relawan/feature/recruit/relawan'),
  },
  {
    path: '/relawan/:id/rekrut-dukungan',
    name: 'DetailRekrutanDukungan',
    component: () => import('@pantaurelawan/dashboard/relawan/feature/recruit/dukungan'),
  },
  {
    path: '/relawan/:id/reset-password',
    name: 'UpdatePassword',
    component: () => import('@pantaurelawan/dashboard/relawan/feature/resetpw'),
  },
  {
    path: '/relawan/:id/aktivitas',
    name: 'DetailListAktivitasRelawan',
    component: () => import('@pantaurelawan/dashboard/relawan/feature/activity'),
  },
  {
    path: '/aktivitas',
    name: 'ListAktivitas',
    component: () => import('@pantaurelawan/dashboard/aktivitas/feature/list'),
  },
  {
    path: '/profil-kandidat',
    name: 'ProfilKandidat',
    component: () => import('@pantaurelawan/dashboard/kandidat/feature'),
  },
  {
    path: '/program',
    name: 'ListProgram',
    component: () => import('@pantaurelawan/dashboard/program/feature/list'),
  },
  {
    path: '/program/create',
    name: 'TambahProgram',
    component: () => import('@pantaurelawan/dashboard/program/feature/create'),
  },
  {
    path: '/program/:id/edit',
    name: 'EditProgram',
    component: () => import('@pantaurelawan/dashboard/program/feature/edit'),
  },
  {
    path: '/program/:id',
    name: 'DetailProgram',
    component: () => import('@pantaurelawan/dashboard/program/feature/detail'),
  },
  {
    path: '/berita',
    name: 'ListBerita',
    component: () => import('@pantaurelawan/dashboard/berita/feature/list'),
  },
  {
    path: '/berita/create',
    name: 'TambahBerita',
    component: () => import('@pantaurelawan/dashboard/berita/feature/create'),
  },
  {
    path: '/berita/:id',
    name: 'DetailBerita',
    component: () => import('@pantaurelawan/dashboard/berita/feature/detail'),
  },
  {
    path: '/berita/:id/edit',
    name: 'EditBerita',
    component: () => import('@pantaurelawan/dashboard/berita/feature/edit'),
  },
  {
    path: '/events/create',
    name: 'TambahKegiatan',
    component: () => import('@pantaurelawan/dashboard/event/feature/create'),
  },
  {
    path: '/events/:id',
    name: 'DetailKegiatan',
    component: () => import('@pantaurelawan/dashboard/event/feature/detail'),
  },
  {
    path: '/events/:id/edit',
    name: 'EditKegiatan',
    component: () => import('@pantaurelawan/dashboard/event/feature/edit'),
  },
  {
    path: '/events',
    name: 'ListKegiatan',
    component: () => import('@pantaurelawan/dashboard/event/feature/list'),
  },
  {
    path: '/struktur-timses',
    name: 'PengaturanStrukturTimses',
    component: () => import('@pantaurelawan/dashboard/pengaturan/feature/struktur-timses'),
  },
  {
    path: '/input-relawan',
    name: 'PengaturanInputRelawan',
    component: () => import('@pantaurelawan/dashboard/pengaturan/feature/form-relawan'),
  },
  {
    path: '/dpt-lock',
    name: 'PengaturanDptLock',
    component: () => import('@pantaurelawan/dashboard/pengaturan/feature/dpt-lock'),
  },
  {
    path: '/hak-akses',
    name: 'PengaturanHakAKses',
    component: () => import('@pantaurelawan/dashboard/pengaturan/feature/hak-akses'),
  },
  {
    path: '/kategori-aktivitas',
    name: 'PengaturanKategoriAktivitas',
    component: () => import('@pantaurelawan/dashboard/pengaturan/feature/kategori-aktivitas'),
  },
  {
    path: '/status-dukungan',
    name: 'PengaturanStatusDukungan',
    component: () => import('@pantaurelawan/dashboard/pengaturan/feature/status-dukungan'),
  },
  {
    path: '/survey',
    name: 'ListSurvey',
    component: () => import('@pantaurelawan/dashboard/survey/feature/list'),
  },
  {
    path: '/survey/create',
    name: 'TambahSurvey',
    component: () => import('@pantaurelawan/dashboard/survey/feature/create'),
  },
  {
    path: '/survey/:id',
    name: 'DetailSurvey',
    component: () => import('@pantaurelawan/dashboard/survey/feature/detail'),
  },
  {
    path: '/pengumuman',
    name: 'ListPengumuman',
    component: () => import('@pantaurelawan/dashboard/pengumuman/feature/list'),
  },
  {
    path: '/pengumuman/create',
    name: 'TambahPengumuman',
    component: () => import('@pantaurelawan/dashboard/pengumuman/feature/create'),
  },
  {
    path: '/pengumuman/:id/edit',
    name: 'EditPengumuman',
    component: () => import('@pantaurelawan/dashboard/pengumuman/feature/edit'),
  },
  {
    path: '/pengumuman/:id',
    name: 'DetailPengumuman',
    component: () => import('@pantaurelawan/dashboard/pengumuman/feature/detail'),
  },
  {
    path: '/aspirasi-relawan',
    name: 'ListAspirasiRelawan',
    component: () => import('@pantaurelawan/dashboard/aspirasi-relawan/feature/list'),
  },
  {
    path: '/aspirasi-relawan/:id',
    name: 'DetailAspirasiRelawan',
    component: () => import('@pantaurelawan/dashboard/aspirasi-relawan/feature/detail'),
  },
  {
    path: '/aspirasi-masyarakat',
    name: 'ListAspirasiMasyarakat',
    component: () => import('@pantaurelawan/dashboard/aspirasi-masyarakat/feature/list'),
  },
  {
    path: '/aspirasi-masyarakat/:id',
    name: 'DetailAspirasiMasyarakat',
    component: () => import('@pantaurelawan/dashboard/aspirasi-masyarakat/feature/detail'),
  },
  {
    path: '/real-count/kandidat',
    name: 'PengaturanKandidat',
    component: () => import('@pantaurelawan/dashboard/real-count/feature/pengaturan'),
  },
  {
    path: '/real-count/saksi',
    name: 'ListSaksi',
    component: () => import('@pantaurelawan/dashboard/saksi/feature/list'),
  },
  {
    path: '/real-count/saksi/:id/tunjuk-relawan',
    name: 'ListSaksiRelawan',
    component: () => import('@pantaurelawan/dashboard/saksi/feature/tunjuksaksi/relawan'),
  },
  {
    path: '/real-count/saksi/:id/tunjuk-dukungan',
    name: 'ListSaksiDukungan',
    component: () => import('@pantaurelawan/dashboard/saksi/feature/tunjuksaksi/dukungan'),
  },
  {
    path: '/real-count/data-masuk',
    name: 'ListDataMasuk',
    component: () => import('@pantaurelawan/dashboard/real-count/feature/data-masuk'),
  },
  {
    path: '/real-count/input-suara',
    name: 'InputSuara',
    component: () => import('@pantaurelawan/dashboard/real-count/feature//input-suara'),
  },
  {
    path: '/real-count/rekapitulasi-suara',
    name: 'RekapitulasiSuara',
    component: () => import('@pantaurelawan/dashboard/real-count/feature/rekapitulasi-suara'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env['BASE_URL']),
  routes,
});

export { router };
