import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

import {
  CandidateSettings,
  PantauRelawanCandidate,
} from '@pantaurelawan/shared/data-access/models';
import { FirestoreCollection } from '@pantaurelawan/shared/config';

import { useAuthUserStore } from './auth-user';

export const useCandidateStore = defineStore('candidateStore', () => {
  const db = getFirestore();
  const authUserStore = useAuthUserStore();

  const state = ref<PantauRelawanCandidate & CandidateSettings>();

  watch(
    () => authUserStore.state,
    async authUser => {
      if (!authUser) return;

      const candidateId = authUser.id_kandidat;
      const candidateRef = doc(db, FirestoreCollection.Candidates, candidateId);
      const candidateSettingsRef = doc(db, FirestoreCollection.CandidateSettings, candidateId);
      const candidateSnap = await getDoc(candidateRef);
      const candidateSettingsSnap = await getDoc(candidateSettingsRef);
      const candidate = candidateSnap.data() as PantauRelawanCandidate;
      const candidateSettings = candidateSettingsSnap.data() as CandidateSettings;

      state.value = { ...candidate, ...candidateSettings };
    },
    { immediate: true }
  );

  return { state };
});
