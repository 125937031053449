import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { CandidateRegionLevels } from '@pantaurelawan/shared/config';

import axios from 'axios';
import { useCandidateStore } from './candidate';

const apiClient = axios.create({
  baseURL: 'https://simpan.lamaccaservices.com',
});

type ItemProvinsiResponse = {
  province_code: string;
  province_name: string;
};

type ItemKabupatenKotaResponse = {
  province_code: string;
  province_name: string;
  regency_code: string;
  regency_name: string;
};

type ItemKecamatanResponse = {
  province_code: string;
  province_name: string;
  regency_code: string;
  regency_name: string;
  district_code: string;
  district_name: string;
};

type ItemDesaKelurahanResponse = {
  province_code: string;
  province_name: string;
  regency_code: string;
  regency_name: string;
  district_code: string;
  district_name: string;
  village_code: string;
  village_name: string;
};

type RegionProps = {
  id: string;
  name: string;
};

export const useWilayahStore = defineStore('saksi', () => {
  const candidateStore = useCandidateStore();

  const candidate = computed(() => candidateStore.state);

  // State list provinsi
  const provinsi = ref<RegionProps[]>([]);

  // State list kabupaten kota
  const kabupatenKota = ref<RegionProps[]>([]);

  // State list kecamatan
  const kecamatan = ref<RegionProps[]>([]);

  // State list desa kelurahan
  const desaKelurahan = ref<RegionProps[]>([]);

  const fetchProvinsi = async () => {
    if (!candidate.value) {
      return [];
    }

    if (candidate.value.level_wilayah === CandidateRegionLevels.Provinsi) {
      provinsi.value = candidate.value.wilayah_pemilihan.map(item => ({
        id: item.id,
        name: item.name,
      }));
    }

    const res = await apiClient.get<{ data: ItemProvinsiResponse[] }>('/api/provinces');

    if (candidate.value.level_wilayah === CandidateRegionLevels.KabupatenKota) {
      provinsi.value = res.data.data
        .filter(provinsi =>
          candidate.value?.wilayah_pemilihan.some(kabupatenKota =>
            kabupatenKota.id.startsWith(provinsi.province_code)
          )
        )
        .map(item => ({
          id: item.province_code,
          name: item.province_name,
        }));
    }

    if (
      candidate.value.level_wilayah !== CandidateRegionLevels.Provinsi &&
      candidate.value.level_wilayah !== CandidateRegionLevels.KabupatenKota
    ) {
      provinsi.value = res.data.data
        .filter(provinsi =>
          candidate.value?.wilayah_pemilihan.some(kabupatenKota =>
            kabupatenKota.id.startsWith(provinsi.province_code)
          )
        )
        .map(item => ({
          id: item.province_code,
          name: item.province_name,
        }));
    }
  };

  const fetchKabupatenKota = async (provinceId: string | null | undefined) => {
    if (!candidate.value) {
      return [];
    }

    if (candidate.value.level_wilayah === CandidateRegionLevels.KabupatenKota) {
      kabupatenKota.value = candidate.value.wilayah_pemilihan.map(item => ({
        id: item.id,
        name: item.name,
      }));
    }

    const res = await apiClient.get<{ data: ItemKabupatenKotaResponse[] }>('/api/regencies', {
      params: {
        province_id: provinceId,
      },
    });

    if (candidate.value.level_wilayah === CandidateRegionLevels.Provinsi) {
      kabupatenKota.value = res.data.data
        .filter(kabupatenKota =>
          candidate.value?.wilayah_pemilihan.some(provinsi =>
            provinsi.id.startsWith(kabupatenKota.province_code)
          )
        )
        .map(item => ({
          id: item.regency_code,
          name: item.regency_name,
        }));

      return [];
    }
    if (candidate.value.level_wilayah === CandidateRegionLevels.Kecamatan) {
      kabupatenKota.value = res.data.data
        .filter(kabupatenKota =>
          candidate.value?.wilayah_pemilihan.some(provinsi =>
            provinsi.id.startsWith(kabupatenKota.regency_code)
          )
        )
        .map(item => ({
          id: item.regency_code,
          name: item.regency_name,
        }));

      return [];
    }

    return [];
  };

  const fetchKecamatan = async (regencyId: string | null | undefined) => {
    if (!candidate.value) {
      return [];
    }

    const res = await apiClient.get<{ data: ItemKecamatanResponse[] }>('/api/districts', {
      params: {
        regency_id: regencyId,
      },
    });

    if (candidate.value.level_wilayah === CandidateRegionLevels.Kecamatan) {
      kecamatan.value = res.data.data
        .filter(kecamatan =>
          candidate.value?.wilayah_pemilihan.some(provinsi =>
            provinsi.id.startsWith(kecamatan.district_code)
          )
        )
        .map(item => ({
          id: item.district_code,
          name: item.district_name,
        }));
    }
    if (candidate.value.level_wilayah !== CandidateRegionLevels.Kecamatan) {
      kecamatan.value = res.data.data.map(item => ({
        id: item.district_code,
        name: item.district_name,
      }));
    }
  };

  const fetchDesaKelurahan = async (districtId: string | null | undefined) => {
    const res = await apiClient.get<{ data: ItemDesaKelurahanResponse[] }>('/api/villages', {
      params: {
        district_id: districtId,
      },
    });

    desaKelurahan.value = res.data.data.map(item => ({
      id: item.village_code,
      name: item.village_name,
    }));
  };

  return {
    provinsi,
    kabupatenKota,
    kecamatan,
    desaKelurahan,
    fetchProvinsi,
    fetchKabupatenKota,
    fetchKecamatan,
    fetchDesaKelurahan,
  };
});
