import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { Vue3ProgressPlugin } from '@marcoschulte/vue3-progress';
import PerfectScrollbar from 'vue3-perfect-scrollbar';

import '@marcoschulte/vue3-progress/dist/index.css';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap';

import { LUXON_LOCALE, LUXON_TIMEZONE } from '@pantaurelawan/shared/config';
import { router } from '@pantaurelawan/dashboard/shared/router';
import { DashboardShell } from '@pantaurelawan/dashboard/shell';
import { initializeFirebase } from './firebase';

import './scss/styles.scss';
import { Settings } from 'luxon';

Settings.defaultLocale = LUXON_LOCALE;
Settings.defaultZone = LUXON_TIMEZONE;

const app = createApp(DashboardShell);
const firebaseApp = initializeFirebase();

app.use(createPinia());
app.use(router);
app.use(Vue3ProgressPlugin);
app.use(PerfectScrollbar);
app.mount('#app');
