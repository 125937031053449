import { ref } from 'vue';
import { defineStore } from 'pinia';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { PantauRelawanUser } from '@pantaurelawan/shared/data-access/models';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { FirestoreCollection } from '@pantaurelawan/shared/config';

export const useAuthUserStore = defineStore('authUserStore', () => {
  const auth = getAuth();
  const db = getFirestore();
  const state = ref<PantauRelawanUser | null>(null);

  onAuthStateChanged(auth, async authUser => {
    if (authUser) {
      const snap = await getDoc(doc(db, FirestoreCollection.Users, authUser.uid));

      state.value = snap.data() as PantauRelawanUser;
    } else {
      state.value = null;
    }
  });

  function logout() {
    auth.signOut();
    state.value = null;
  }

  return { state, logout };
});
