import { getApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { FUNCTIONS_REGION } from '@pantaurelawan/shared/config';

export function useFirebase() {
  const app = getApp();
  const db = getFirestore(app);
  const storage = getStorage(app);
  const functions = getFunctions(app, FUNCTIONS_REGION);

  return { app, db, storage, functions };
}
