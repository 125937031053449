import { watchEffect, computed } from 'vue';
import { useAuthUserStore } from '@pantaurelawan/dashboard/shared/data-access/store';
import { PantauRelawanUser } from '@pantaurelawan/shared/data-access/models';

export function useUserLoaded(callback: (authUser: PantauRelawanUser) => void) {
  const authUserStore = useAuthUserStore();
  const authUser = computed(() => authUserStore.state);

  watchEffect(() => {
    if (authUser.value) {
      callback(authUser.value);
    }
  });
}
