import { ref, computed } from 'vue';
import { QueryDocumentSnapshot, DocumentData, QuerySnapshot } from 'firebase/firestore';
import { DEFAULT_ITEMS_PER_PAGE } from '@pantaurelawan/shared/config';

type FetchType = 'FETCH_INITIAL' | 'FETCH_NEXT' | 'FETCH_PREVIOUS';

export function usePagination() {
  const currentPage = ref<number>(1);
  const firstDocument = ref<QueryDocumentSnapshot<DocumentData> | undefined>();
  const lastDocument = ref<QueryDocumentSnapshot<DocumentData> | undefined>();
  const hasNext = ref<boolean>(false);
  const hasPrevious = computed(() => currentPage.value > 1);
  const shouldShowPagination = computed(() => hasNext.value || hasPrevious.value);

  function countPagination(items: QuerySnapshot<DocumentData>, fetchType: FetchType) {
    if (fetchType === 'FETCH_NEXT') {
      currentPage.value++;
    }

    if (fetchType === 'FETCH_PREVIOUS') {
      currentPage.value--;
    }

    firstDocument.value = items.docs[0];
    lastDocument.value = items.docs[items.size - 1];
    hasNext.value = items.size === DEFAULT_ITEMS_PER_PAGE;
  }

  return {
    currentPage,
    firstDocument,
    lastDocument,
    hasNext,
    hasPrevious,
    shouldShowPagination,
    countPagination,
  };
}
