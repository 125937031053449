import { FormFieldSetting } from '@pantaurelawan/shared/data-access/models';

export const FORM_INPUTS: Record<string, string> = {
  nik: 'NIK',
  nomor_kk: 'Nomor KK',
  nama_lengkap: 'Nama Lengkap',
  jenis_kelamin: 'Jenis Kelamin',
  status_perkawinan: 'Status Perkawinan',
  tempat_lahir: 'Tempat Lahir',
  tanggal_lahir: 'Tanggal Lahir',
  alamat: 'Alamat Lengkap',
  tps: 'TPS',
  rt: 'RT',
  rw: 'RW',
  desa_kelurahan: 'Desa/Kelurahan',
  kecamatan: 'Kecamatan',
  kabupaten_kota: 'Kabupaten/Kota',
  provinsi: 'Provinsi',
  agama: 'Agama',
  pekerjaan: 'Pekerjaan',
  nomor_telepon: 'Nomor Telepon',
  nomor_wa: 'Nomor Whatsapp',
  foto_diri: 'Foto Diri',
  foto_ktp: 'Foto KTP',
  foto_bukti_rekrut: 'Foto Bukti Perekrutan',
};

export const BASE_INPUT_SETTINGS: FormFieldSetting = {
  editable: false,
  mandatory: false,
  show: true,
};
