import { ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { getBlob, getStorage, ref as storageReference } from 'firebase/storage';
import { useLoading } from '@pantaurelawan/dashboard/shared/data-access/composables';
import { useFlashMessageStore } from './flash-message';
import { useAuthUserStore } from './auth-user';
import { IMAGE_PLACEHOLDER } from '@pantaurelawan/shared/config';

export const useFetchKtp = defineStore('fetchKtpStore', () => {
  const { flashError } = useFlashMessageStore();
  const { state: loading, startLoading, finishLoading } = useLoading();
  const authUserStore = useAuthUserStore();
  const { state: authUser } = storeToRefs(authUserStore);

  // State untuk Foto KTP
  const imageSrc = ref('');

  async function fetchFotoKtp(imageRef: string | undefined) {
    const storage = getStorage();
    const fileRef = storageReference(storage, imageRef);

    try {
      startLoading();

      if (!authUser.value) {
        throw new Error('Harap Login Terlebih Dahulu');
      }

      const blob = await getBlob(fileRef);

      const url = URL.createObjectURL(blob);
      imageSrc.value = url;
    } catch (error) {
      flashError(error);
      imageSrc.value = IMAGE_PLACEHOLDER;
    } finally {
      finishLoading();
    }
  }

  return {
    imageSrc,
    loading,
    fetchFotoKtp,
  };
});
